<template>
  <MainLayout class="main-layout--service-terms">
    <section>
      <header class="mb-xxl">
        <Heading class="mb-lg">
          {{ $t('terms.title') }}
        </Heading>
        <nav>
          <Heading
            :tag="'h3'"
            :level="'h3'"
            class="mb-lg">
            {{ $t('terms.menu') }}
          </Heading>
          <ol class="ml-lg">
            <li
              v-for="(navLink, index) in navLinks"
              :key="`nav-link-${index}`"
              class="mb-xs">
              <Heading
                :tag="'h3'"
                :level="'h4'"
                isUnderlined>
                <router-link :to="navLink.link">
                  {{ navLink.number }}
                  <!-- {{ `2.${index + 1}`}} -->
                  {{ $t(navLink.label) }}
                </router-link>
              </Heading>
            </li>
          </ol>
        </nav>
      </header>

      <ol>
        <li class="mb-xxl">
          <section>
            <header>
              <Heading
                :tag="'h2'"
                :level="'h2'"
                id="terms"
                class="mb-lg">
                1. {{ $t('terms.terms.title') }}
              </Heading>
            </header>
            <Paragraph
              v-html="$t('terms.terms.text')"
              class="ml-lg">
            </Paragraph>
          </section>
        </li>
        <li class="mb-xxl">
          <section>
            <header>
              <Heading
                :tag="'h2'"
                :level="'h2'"
                id="general"
                class="mb-lg">
                2. {{ $t('terms.conditions.title') }}
              </Heading>
            </header>
            <ol class="ml-lg">
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  id="objects"
                  class="mb-xs">
                  Article 2.1. {{ $t('terms.conditions.objects.title') }}
                </Heading>
                <Paragraph v-html="$t('terms.conditions.objects.text')"></Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="utilisation">
                  Article 2.2. {{ $t('terms.conditions.utilisation.title') }}
                </Heading>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.1. {{ $t('terms.conditions.utilisation.inscription.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.inscription.text')"
                  class="mb-sm">
                </Paragraph>

                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.2. {{ $t('terms.conditions.utilisation.profile.title') }}
                </Heading>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.2.1. {{ $t('terms.conditions.utilisation.profile.description.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.profile.description.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.2.2. {{ $t('terms.conditions.utilisation.profile.medias.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.profile.medias.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.2.3. {{ $t('terms.conditions.utilisation.profile.duration.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.profile.duration.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.2.4. {{ $t('terms.conditions.utilisation.profile.other.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.profile.other.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.2.5. {{ $t('terms.conditions.utilisation.profile.faq.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.profile.faq.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.2.6. {{ $t('terms.conditions.utilisation.profile.equipement.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.profile.equipement.text')"
                  class="mb-sm">
                </Paragraph>

                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.3. {{ $t('terms.conditions.utilisation.agendaAndPricing.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.agendaAndPricing.text')"
                  class="mb-sm">
                </Paragraph>

                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.4. {{ $t('terms.conditions.utilisation.booking.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.booking.text')"
                  class="mb-sm">
                </Paragraph>

                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.5. {{ $t('terms.conditions.utilisation.payroll.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.payroll.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.5.1. {{ $t('terms.conditions.utilisation.payroll.stripe.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.payroll.stripe.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.5.2. {{ $t('terms.conditions.utilisation.payroll.intermittent.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.payroll.intermittent.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.5.3. {{ $t('terms.conditions.utilisation.payroll.company.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.payroll.company.text')"
                  class="mb-sm">
                </Paragraph>

                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.6. {{ $t('terms.conditions.utilisation.artistBehavior.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.artistBehavior.text')"
                  class="mb-sm">
                </Paragraph>

                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.7. {{ $t('terms.conditions.utilisation.artistReviews.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.artistReviews.text')"
                  class="mb-sm">
                </Paragraph>

                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.2.8. {{ $t('terms.conditions.utilisation.cancellation.title') }}
                </Heading>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.8.1. {{ $t('terms.conditions.utilisation.cancellation.cancellationByClient.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.cancellation.cancellationByClient.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.8.2. {{ $t('terms.conditions.utilisation.cancellation.cancellationByArtist.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.cancellation.cancellationByArtist.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h5'"
                  :level="'h5'"
                  class="mb-xs">
                  Article 2.2.8.3. {{ $t('terms.conditions.utilisation.cancellation.cancellationByForce.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.utilisation.cancellation.cancellationByForce.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="obligation">
                  Article 2.3. {{ $t('terms.conditions.obligation.title') }}
                </Heading>
                <Paragraph v-html="$t('terms.conditions.obligation.text')"></Paragraph>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mt-sm mb-xs">
                  {{ $t('terms.conditions.obligation.reportProcess.title') }}
                </Heading>
                <Paragraph v-html="$t('terms.conditions.obligation.reportProcess.text')"></Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="responsabilities">
                  Article 2.4. {{ $t('terms.conditions.responsabilities.title') }}
                </Heading>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.4.1. {{ $t('terms.conditions.responsabilities.intermittent.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.responsabilities.intermittent.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.4.2. {{ $t('terms.conditions.responsabilities.company.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.responsabilities.company.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="financial">
                  Article 2.5. {{ $t('terms.conditions.financialConditions.title') }}
                </Heading>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.5.1. {{ $t('terms.conditions.financialConditions.pricing.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.financialConditions.pricing.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.5.2. {{ $t('terms.conditions.financialConditions.remuneration.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.financialConditions.remuneration.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="duration">
                  Article 2.6. {{ $t('terms.conditions.termsDuration.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.termsDuration.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="intellectual">
                  Article 2.7. {{ $t('terms.conditions.intellectualProperty.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.intellectualProperty.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="data">
                  Article 2.8. {{ $t('terms.conditions.personalData.title') }}
                </Heading>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.8.1. {{ $t('terms.conditions.personalData.data.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.personalData.data.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.8.2. {{ $t('terms.conditions.personalData.cookies.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.personalData.cookies.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="technical">
                  Article 2.9. {{ $t('terms.conditions.technical.title') }}
                </Heading>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.9.1. {{ $t('terms.conditions.technical.usage.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.technical.usage.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.9.2. {{ $t('terms.conditions.technical.externalApps.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.technical.externalApps.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="security">
                  Article 2.10. {{ $t('terms.conditions.security.title') }}
                </Heading>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.10.1. {{ $t('terms.conditions.security.generalities.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.security.generalities.text')"
                  class="mb-sm">
                </Paragraph>
                <Heading
                  :tag="'h4'"
                  :level="'h4'"
                  class="mb-xs">
                  Article 2.10.2. {{ $t('terms.conditions.security.endOfService.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.security.endOfService.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="force">
                  Article 2.11. {{ $t('terms.conditions.majorForce.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.majorForce.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="integrity">
                  Article 2.12. {{ $t('terms.conditions.integrity.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.integrity.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="renonciation">
                  Article 2.13. {{ $t('terms.conditions.nonRenonciation.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.nonRenonciation.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="nullity">
                  Article 2.14. {{ $t('terms.conditions.nullity.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.nullity.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
              <li class="mb-lg">
                <Heading
                  :tag="'h3'"
                  :level="'h3'"
                  class="mb-xs"
                  id="juridiction">
                  Article 2.15. {{ $t('terms.conditions.juridiction.title') }}
                </Heading>
                <Paragraph
                  v-html="$t('terms.conditions.juridiction.text')"
                  class="mb-sm">
                </Paragraph>
              </li>
            </ol>
          </section>
        </li>
      </ol>
    </section>
  </MainLayout>
</template>

<script>

import MainLayout     from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Heading        from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'p-ServiceTerms',
  components: {
    MainLayout,
    Heading,
    Paragraph,
  },
  computed: {
    navLinks() {
      return [
        {
          label: 'terms.terms.title',
          link: '/terms#terms',
          number: '1.',
        },
        {
          label: 'terms.conditions.title',
          link: '/terms#general',
          number: '2.',
        },
        {
          label: 'terms.conditions.objects.title',
          link: '/terms#objects',
          number: '2.1',
        },
        {
          label: 'terms.conditions.utilisation.title',
          link: '/terms#utilisation',
          number: '2.2',
        },
        {
          label: 'terms.conditions.obligation.title',
          link: '/terms#obligation',
          number: '2.3',
        },
        {
          label: 'terms.conditions.responsabilities.title',
          link: '/terms#responsabilities',
          number: '2.4',
        },
        {
          label: 'terms.conditions.financialConditions.title',
          link: '/terms#financial',
          number: '2.5',
        },
        {
          label: 'terms.conditions.termsDuration.title',
          link: '/terms#duration',
          number: '2.6',
        },
        {
          label: 'terms.conditions.intellectualProperty.title',
          link: '/terms#intellectual',
          number: '2.7',
        },
        {
          label: 'terms.conditions.personalData.title',
          link: '/terms#data',
          number: '2.8',
        },
        {
          label: 'terms.conditions.technical.title',
          link: '/terms#technical',
          number: '2.9',
        },
        {
          label: 'terms.conditions.security.title',
          link: '/terms#security',
          number: '2.10',
        },
        {
          label: 'terms.conditions.majorForce.title',
          link: '/terms#force',
          number: '2.11',
        },
        {
          label: 'terms.conditions.integrity.title',
          link: '/terms#integrity',
          number: '2.12',
        },
        {
          label: 'terms.conditions.nonRenonciation.title',
          link: '/terms#renonciation',
          number: '2.13',
        },
        {
          label: 'terms.conditions.nullity.title',
          link: '/terms#nullity',
          number: '2.14',
        },
        {
          label: 'terms.conditions.juridiction.title',
          link: '/terms#juridiction',
          number: '2.15',
        },
      ];
    },
  }
}

</script>
